import { createTheme, Theme } from "@mui/material";
import { RefineThemes } from "@refinedev/mui";

export const customTheme: Theme = createTheme({
  ...RefineThemes.Blue,
  palette: {
    background: {
      default: "#f5f5f5",
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {},
      defaultProps: {
        shrink: true, // Shrink the text label by default, prevent it overlapping with the value
      },
    },
  },
});
