import { GridColDef } from "@mui/x-data-grid";
import { MemberTicket } from "../types/ticketType";
import { ImageColumn } from "../components/table/ImageColumn";
import React from "react";

export const ticketCols = (): GridColDef<
  MemberTicket & { index: number }
>[] => [
  {
    field: "id",
    headerName: "Id",
  },
  {
    field: "image",
    headerName: "Image",
    renderCell: function render({ row }) {
      if (!row.image) {
        return null;
      }
      return (
        <ImageColumn image={row.image} borderRadius={"10%"} maxWidth={8} />
      );
    },
    minWidth: 120,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "description",
    headerName: "description",
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "created at",
    flex: 1,
  },
];
