import { useAuth0 } from "@auth0/auth0-react";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CelebrationIcon from "@mui/icons-material/Celebration";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import InfoIcon from "@mui/icons-material/Info";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import CategoryIcon from "@mui/icons-material/Category";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import PaletteIcon from "@mui/icons-material/Palette";
import TollIcon from "@mui/icons-material/Toll";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import FeedIcon from "@mui/icons-material/Feed";
import {
  Authenticated,
  CanAccess,
  ErrorComponent,
  Refine,
  useCan,
} from "@refinedev/core";
import {
  RefineSnackbarProvider,
  ThemedLayoutV2,
  useNotificationProvider,
} from "@refinedev/mui";
import routerProvider from "@refinedev/react-router-v6";
import BranchList from "pages/branches/BranchList";
import CreateBranch from "pages/branches/CreateBranch";
import EditBranch from "pages/branches/EditBranch";
import AirdropPage from "pages/coupons/airdrops/AirdropPage";
import CouponList from "pages/coupons/CouponList";
import CreateCouponType from "./pages/coupons/CreateCouponType";
import CreateEvent from "pages/events/CreateEvent";
import EditEvent from "pages/events/EditEvent";
import EventList from "pages/events/EventList";
import EventStatistics from "pages/events/statistics/EventStatistics";
import { Login } from "pages/Login";
import EditMembershipType from "./pages/membership-types/EditMembershipType";
import MembershipTypeList from "./pages/membership-types/MembershipTypeList";
import CreateRedemptions from "pages/redemptions/CreateRedemptions";
import RedemptionList from "pages/redemptions/RedemptionList";
import CreateUser from "pages/users/CreateUser";
import EditUser from "pages/users/EditUser";
import UserList from "pages/users/UserList";
import { createAccessControlProvider } from "provider/AccessControlProvider";
import { AccountProvider } from "provider/AccountProvider";
import { authProvider } from "provider/AuthProvider";
import { useAxios } from "provider/AxiosProvider";
import { ConfigProvider } from "provider/ConfigProvider";
import { dataProvider } from "provider/DataProvider";
import { Web3Provider } from "provider/Web3Provider";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useAxiosErrorHandling } from "utils/rest/useAxiosErrorHandling";
import InfoPage from "./pages/info/InfoPage";
import { CustomSider } from "./components/layout/CustomSider";
import CustomHeader from "./components/layout/header/CustomHeader";
import { BranchProvider, useBranches } from "./provider/BranchProvider";
import React from "react";
import CreateMembershipType from "./pages/membership-types/CreateMembershipType";
import RedeemIcon from "@mui/icons-material/Redeem";
import EditBranchStyle from "./pages/branch-style/EditBranchStyle";
import { CoinList } from "./pages/coin/CoinList";
import { CreateCoin } from "./pages/coin/CreateCoin";
import NewsList from "./pages/news/NewsList";
import { CreateNews } from "./pages/news/CreateNews";
import { EditNews } from "./pages/news/EditNews";
import MemberList from "./pages/members/MemberList";
import { ShowMember } from "./pages/members/ShowMember";
import { CreateMember } from "./pages/members/CreateMember";
import { DashboardPage } from "./pages/dashboard/DashboardPage";
import TransactionList from "./pages/transactions/TransactionList";
import { TransactionDetails } from "./pages/transactions/TransactionDetails";

export default function RefineWrapper() {
  const axios = useAxios();
  const { user, logout, getIdTokenClaims } = useAuth0();
  useAxiosErrorHandling(axios, logout);

  return (
    <RefineSnackbarProvider>
      <Refine
        dataProvider={dataProvider("/api", axios)}
        notificationProvider={useNotificationProvider}
        routerProvider={routerProvider}
        authProvider={authProvider(axios, user, logout, getIdTokenClaims)}
        accessControlProvider={createAccessControlProvider(axios)}
        resources={[
          {
            name: "dashboard",
            list: "/branches/:branchId",
            meta: {
              label: "Dashboard",
              icon: <DashboardIcon />,
            },
          },
          {
            name: "members",
            list: "/branches/:branchId/members",
            create: "/branches/:branchId/members/create",
            edit: "/branches/:branchId/members/edit/:id",
            show: "/branches/:branchId/members/show/:id",
            meta: {
              label: "Members",
              icon: <PeopleIcon />,
            },
          },
          {
            name: "redemptions",
            list: "/branches/:branchId/redemptions",
            create: "/branches/:branchId/redemptions/create",
            meta: {
              label: "Redemptions",
              icon: <RedeemIcon />,
            },
          },
          {
            name: "events",
            list: "/branches/:branchId/events",
            create: "/branches/:branchId/events/create",
            edit: "/branches/:branchId/events/edit/:id",
            show: "/branches/:branchId/events/statistics/:id",
            meta: {
              label: "Events",
              icon: <CelebrationIcon />,
            },
          },
          {
            name: "news",
            list: "/branches/:branchId/news",
            create: "/branches/:branchId/news/create",
            edit: "/branches/:branchId/news/edit/:id",
            meta: {
              label: "News",
              icon: <FeedIcon />,
            },
          },
          {
            name: "coupon-types",
            list: "/branches/:branchId/coupon-types",
            create: "/branches/:branchId/coupon-types/create",
            show: "/branches/:branchId/coupon-types/edit/:id",
            meta: {
              label: "Coupons",
              icon: <ConfirmationNumberIcon />,
            },
          },
          {
            name: "transactions",
            list: "/branches/:branchId/transactions",
            show: "/branches/:branchId/transactions/show/:id",
            meta: {
              label: "Transactions",
              icon: <CompareArrowsIcon />,
            },
          },
          {
            name: "manageBranch",
            meta: {
              label: "Manage",
              icon: <SettingsApplicationsIcon />,
            },
          },
          {
            name: "membership-types",
            list: "/branches/:branchId/membership-types",
            create: "/branches/:branchId/membership-types/create",
            edit: "/branches/:branchId/membership-types/edit/:id",
            meta: {
              parent: "manageBranch",
              label: "Membership Types",
              icon: <CategoryIcon />,
            },
          },
          {
            name: "coins",
            list: "/branches/:branchId/coins",
            create: "/branches/:branchId/coins/create",
            meta: {
              parent: "manageBranch",
              label: "Coin",
              icon: <TollIcon />,
            },
          },
          {
            name: "branchStyles",
            list: "/branches/:branchId/style",
            meta: {
              parent: "manageBranch",
              label: "Style",
              icon: <PaletteIcon />,
            },
          },
          {
            name: "admin",
            meta: {
              label: "Admin",
              icon: <AdminPanelSettingsIcon />,
              adminBranch: true,
            },
          },
          {
            name: "branches",
            list: "/admin/branches",
            create: "/admin/branches/create",
            edit: "/admin/branches/edit/:id",
            show: "/admin/branches/:id",
            meta: {
              parent: "admin",
              label: "Branches",
              icon: <LocationCityIcon />,
            },
          },
          {
            name: "users",
            list: "/admin/users",
            create: "/admin/users/create",
            edit: "/admin/users/edit/:id",
            meta: {
              parent: "admin",
              label: "Users",
              icon: <SupervisorAccountIcon />,
            },
          },
          {
            name: "info",
            list: "/admin/info",
            meta: {
              parent: "admin",
              label: "Info",
              icon: <InfoIcon />,
            },
          },
        ]}
      >
        <CustomRoutes />
      </Refine>
    </RefineSnackbarProvider>
  );
}

function CustomRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        element={
          <Authenticated key="login" redirectOnFail="/login">
            <AccountProvider>
              <ConfigProvider>
                <Web3Provider>
                  <BranchProvider>
                    <ThemedLayoutV2 Header={CustomHeader} Sider={CustomSider}>
                      <CanAccess>
                        <Outlet />
                      </CanAccess>
                    </ThemedLayoutV2>
                  </BranchProvider>
                </Web3Provider>
              </ConfigProvider>
            </AccountProvider>
          </Authenticated>
        }
      >
        <Route index element={<NavigateToDefaultLocation />} />
        <Route path="/branches/:branchId">
          <Route index element={<DashboardPage />} />
          <Route path="members">
            <Route index element={<MemberList />} />
            <Route path="create" element={<CreateMember />} />
            <Route path="edit/:id" element={<ShowMember />} />
            <Route path="show/:id" element={<ShowMember />} />
          </Route>
          <Route path="redemptions">
            <Route index element={<RedemptionList />} />
            <Route path="create" element={<CreateRedemptions />} />
          </Route>
          <Route path="events">
            <Route index element={<EventList />} />
            <Route path="create" element={<CreateEvent />} />
            <Route path="edit/:id" element={<EditEvent />} />
            <Route path="statistics/:id" element={<EventStatistics />} />
          </Route>
          <Route path="news">
            <Route index element={<NewsList />} />
            <Route path="create" element={<CreateNews />} />
            <Route path="edit/:id" element={<EditNews />} />
          </Route>
          <Route path="coupon-types">
            <Route index element={<CouponList />} />
            <Route path="create" element={<CreateCouponType />} />
            <Route path="edit/:id" element={<AirdropPage />} />
          </Route>
          <Route path="membership-types">
            <Route index element={<MembershipTypeList />} />
            <Route path="create" element={<CreateMembershipType />} />
            <Route path="edit/:id" element={<EditMembershipType />} />
          </Route>
          <Route path="coins">
            <Route index element={<CoinList />} />
            <Route path="create" element={<CreateCoin />} />
          </Route>
          <Route path="style">
            <Route index element={<EditBranchStyle />} />
          </Route>
          <Route path="transactions">
            <Route index element={<TransactionList />} />
            <Route path="show/:id" element={<TransactionDetails />} />
          </Route>
        </Route>
        <Route path="/admin">
          <Route path="info" element={<InfoPage />} />
          <Route path="branches">
            <Route index element={<BranchList />} />
            <Route path="create" element={<CreateBranch />} />
            <Route path="edit/:id" element={<EditBranch />} />
          </Route>
          <Route path="users">
            <Route index element={<UserList />} />
            <Route path="create" element={<CreateUser />} />
            <Route path="edit/:id" element={<EditUser />} />
          </Route>
        </Route>
        <Route path="*" element={<ErrorComponent />} />
      </Route>
    </Routes>
  );
}

function NavigateToDefaultLocation() {
  const branches = useBranches();
  const canAdmin = useCan({ resource: "admin", action: "list" }).data?.can;

  function findDefaultPath() {
    if (branches?.length === 1) return `/branches/${branches[0].id}`;
    if (canAdmin) return "/admin/branches";
    return "/error";
  }

  const defaultPath = findDefaultPath();
  return <Navigate to={defaultPath} />;
}
