import React from "react";
import { DashboardData } from "../../types/dashboardData";
import { GraphicalDashboardCard } from "../../components/dashboard/GraphicalDashboardCard";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

export function MembershipsCard(props: { data: DashboardData }) {
  const { data } = props;

  const options: ApexOptions = {
    series: data.members.membershipCountPerType.map(
      (membershipTypeCount) => membershipTypeCount.count
    ),
    labels: data.members.membershipCountPerType.map(
      (membershipTypeCount) => membershipTypeCount.membershipType.name
    ),
  };

  return (
    <GraphicalDashboardCard
      gridProps={{ xs: 12, md: 6 }}
      title={"Types of memberships held"}
      resource={"membership-types"}
    >
      <Chart options={options} series={options.series} type="pie" width={400} />
    </GraphicalDashboardCard>
  );
}
