import { EntityRef } from "utils/rest/entities";
import { MembershipType } from "./membershipType";
import { propertiesOf } from "../utils/propertyOf";

/** Mirrors key2-server's [com.kurzdigital.key2.key2server.dto.RedemptionDto] */
export interface Redemption extends EntityRef {
  id: number;
  code: string;
  lotId: string;
  status: RedemptionStatus;
  membershipType: MembershipType;
  includedCoinAmount: number;
  mintAddress: string;
  serial: number;
  createdAt: string;
  redeemedAt: string | null;
  qrCodeUrl: string;
}

export enum RedemptionStatus {
  VALID = "VALID",
  REDEEMED = "REDEEMED",
}

export interface CreateRedemptionsRequest {
  membershipTypeId: number | null;
  amountOfRedemptions: number;
  includedCoinAmount?: number | string;
}

export const defaultRedemptionsRequest: CreateRedemptionsRequest = {
  membershipTypeId: null,
  amountOfRedemptions: 1,
  includedCoinAmount: "",
};

export const ofCreateRedemption = propertiesOf<CreateRedemptionsRequest>();
export const ofRedemption = propertiesOf<Redemption>();
