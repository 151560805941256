import { Box, useTheme } from "@mui/material";
import DeleteMenuItem from "components/table/DeleteMenuItem";
import { MoreActionsButton } from "components/table/MoreActionsButton";
import QrCodeDownloadMenuItem from "components/table/QrCodeDownloadMenuItem";
import { useConfig } from "provider/ConfigProvider";
import QRCode from "react-qr-code";
import { Event } from "types/event";

export const EventMenu = (props: { event: Event }) => {
  const { event } = props;
  const theme = useTheme();
  const { staffAppUrl } = useConfig();

  return (
    <Box position="absolute" top={theme.spacing(1)} right={theme.spacing(1)}>
      <MoreActionsButton>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: 8,
            marginBottom: 16,
          }}
        >
          <QRCode
            value={`${staffAppUrl}://event?code=${event.code}`}
            size={128}
          />
        </div>
        <QrCodeDownloadMenuItem
          url={`${staffAppUrl}://event?code=${event.code}`}
          filename={`${event.slug}-code.png`}
        />
        <DeleteMenuItem resource="events" id={event.id} />
      </MoreActionsButton>
    </Box>
  );
};
