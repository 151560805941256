import { GridColDef } from "@mui/x-data-grid";
import { ImageColumn } from "../components/table/ImageColumn";
import { Coupon, ofCoupon } from "../types/coupon";

export const couponCols = (): GridColDef<Coupon>[] => [
  {
    field: ofCoupon("id"),
    headerName: "Id",
  },
  {
    field: ofCoupon("image"),
    headerName: "Image",
    renderCell: function render({ row }) {
      return (
        <ImageColumn image={row.image} borderRadius={"10%"} maxWidth={8} />
      );
    },
  },
  {
    field: ofCoupon("name"),
    headerName: "Name",
    flex: 1,
  },
  {
    field: ofCoupon("externalUrl"),
    headerName: "External link",
    flex: 1,
  },
  {
    field: ofCoupon("validUntil"),
    headerName: "Valid until",
    renderCell: function render({ row }) {
      return row.validUntil ? new Date(row.validUntil).toLocaleString() : "-";
    },
    flex: 1,
    minWidth: 100,
  },
];
