import { MembershipType } from "../../types/membershipType";
import React, { useState } from "react";
import { OpenInSolanaExplorerButton } from "../OpenInSolanaExplorerButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Stack, Tooltip } from "@mui/material";

export function MembershipTypeCell(props: {
  membershipType: MembershipType;
  mintAddress: string;
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState("Copied to clipboard");

  const handleTooltip = (text: string) => {
    setTooltipTitle(text);
    setShowTooltip(true);
  };
  return (
    <Stack direction="row" gap={1}>
      <img
        style={{ height: 50 }}
        src={props.membershipType.image?.url}
        alt="membership-nft"
      />
      {props.membershipType.name}
      <Tooltip
        title={tooltipTitle}
        open={showTooltip}
        leaveDelay={1000}
        onClose={() => setShowTooltip(false)}
      >
        <IconButton
          size="small"
          onClick={async (event) => {
            event.stopPropagation();
            await navigator.clipboard.writeText(props.mintAddress);
            handleTooltip("Copied to clipboard");
          }}
        >
          <ContentCopyIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <OpenInSolanaExplorerButton pubKey={props.mintAddress} type="address" />
    </Stack>
  );
}
