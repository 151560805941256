import { GridColDef } from "@mui/x-data-grid";
import { Member, ofMember } from "../types/member";
import { Membership } from "../types/membership";
import React from "react";
import { Box, Tooltip } from "@mui/material";
import { Coupon } from "../types/coupon";
import { MemberTicket } from "../types/ticketType";
import { Coin } from "../types/coin";
import CoinAmount from "../components/CoinAmount";
import { ImageColumn } from "../components/table/ImageColumn";
import { MoreActionsButton } from "../components/table/MoreActionsButton";
import IssueCoinMenuItem from "../components/table/IssueCoinMenuItem";

export const memberCols = (props: {
  coin?: Coin | null;
  personalData?: boolean | null;
}): GridColDef<Member>[] => {
  const optionalCoinCol: GridColDef<Member>[] = props.coin
    ? [
        {
          field: ofMember("coinBalance"),
          headerName: "Coin Balance",
          flex: 1,
          renderCell: ({ row }) => {
            if (!row.coinBalance) return "";
            return <CoinAmount amount={row.coinBalance.balance} />;
          },
        },
      ]
    : [];

  const optionalPersonalData: GridColDef<Member>[] = props.personalData
    ? [
        {
          field: ofMember("name"),
          headerName: "Name",
          flex: 1,
          renderCell: ({ row }) => {
            if (!row.name) return "";
            return row.name;
          },
        },
        {
          field: ofMember("email"),
          headerName: "Email",
          flex: 1,
          renderCell: ({ row }) => {
            if (!row.email) return "";
            return row.email;
          },
        },
        {
          field: ofMember("company"),
          headerName: "Company",
          flex: 1,
          renderCell: ({ row }) => {
            if (!row.company) return "";
            return row.company;
          },
        },
      ]
    : [];

  const optionalMoreButton: GridColDef<Member>[] = props.coin
    ? [
        {
          field: " ",
          renderCell: function render({ row }) {
            if (row.id == null) return <div></div>;
            return (
              <MoreActionsButton>
                <IssueCoinMenuItem
                  resource={"issue-coin"}
                  address={row.walletAddress}
                  coinId={props.coin!.id as number}
                />
              </MoreActionsButton>
            );
          },
          align: "center",
          maxWidth: 80,
          filterable: false,
          sortable: false,
          disableExport: true,
          disableColumnMenu: true,
        },
      ]
    : [];

  return [
    ...optionalPersonalData,
    {
      field: ofMember("walletAddress"),
      headerName: "Wallet Address",
      flex: 1,
      renderCell: (params) => {
        const address = params.value;
        var formattedAddress = address;
        if (!address) return <div></div>;
        if (address.length > 8) {
          formattedAddress = `${address.slice(0, 4)}...${address.slice(-4)}`;
        }
        return (
          <Tooltip title={address} arrow>
            <span style={{ cursor: "pointer", color: "#1976d2" }}>
              {formattedAddress}
            </span>
          </Tooltip>
        );
      },
    },
    ...optionalCoinCol,
    {
      field: ofMember("memberships"),
      headerName: "Memberships",
      flex: 1,
      renderCell: (params) => {
        const maxDisplayCount = 5;
        return (
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={1}
            width={"100%"}
            height={"100%"}
            alignItems={"center"}
          >
            {params.value
              .slice(0, maxDisplayCount)
              .map((membership: Membership) => (
                <Tooltip title={membership.name} arrow>
                  <span style={{ cursor: "pointer" }}>
                    <ImageColumn
                      image={membership.image}
                      borderRadius={"10%"}
                    />
                  </span>
                </Tooltip>
              ))}
            {params.value.length > maxDisplayCount && (
              <span style={{ fontSize: "1rem", marginLeft: "0.5rem" }}>
                ...
              </span>
            )}
          </Box>
        );
      },
    },
    {
      field: ofMember("tickets"),
      headerName: "Tickets",
      flex: 1,
      renderCell: (params) => {
        const maxDisplayCount = 3;
        return (
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={1}
            width={"100%"}
            height={"100%"}
            alignItems={"center"}
          >
            {params.value
              .slice(0, maxDisplayCount)
              .map((ticket: MemberTicket) => (
                <>
                  <Tooltip title={ticket.name} arrow>
                    <span style={{ cursor: "pointer" }}>
                      <ImageColumn
                        image={ticket.image}
                        borderRadius={"10%"}
                        maxWidth={8}
                      />
                    </span>
                  </Tooltip>
                </>
              ))}
            {params.value.length > maxDisplayCount && (
              <span style={{ fontSize: "1rem", marginLeft: "0.5rem" }}>
                ...
              </span>
            )}
          </Box>
        );
      },
    },
    {
      field: ofMember("coupons"),
      headerName: "Coupons",
      flex: 1,
      renderCell: (params) => {
        const maxDisplayCount = 3;
        return (
          <Box
            display={"flex"}
            flexWrap={"wrap"}
            overflow={"hidden"}
            flexDirection={"row"}
            gap={1}
            width={"100%"}
            height={"100%"}
            alignItems={"center"}
          >
            {params.value.slice(0, maxDisplayCount).map((coupon: Coupon) => (
              <>
                <Tooltip title={coupon.name} arrow>
                  <span style={{ cursor: "pointer" }}>
                    <ImageColumn
                      image={coupon.image}
                      borderRadius={"10%"}
                      maxWidth={8}
                    />
                  </span>
                </Tooltip>
              </>
            ))}
            {params.value.length > maxDisplayCount && (
              <span style={{ fontSize: "1rem", marginLeft: "0.5rem" }}>
                ...
              </span>
            )}
          </Box>
        );
      },
    },
    ...optionalMoreButton,
  ];
};
