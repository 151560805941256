import { useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { List, useDataGrid } from "@refinedev/mui";
import { useAxios } from "provider/AxiosProvider";
import { useState } from "react";
import { redemptionCols } from "tables/redemptionCols";
import { apiHost } from "utils/rest/restApi";
import { Redemption } from "../../types/redemption";
import RedemptionFilter from "./RedemptionFilter";
import { useBranch } from "../../provider/BranchProvider";

export default function RedemptionList() {
  const branch = useBranch();
  const axios = useAxios();
  const [refreshing, setRefreshing] = useState(false);
  const { dataGridProps, setFilters, filters } = useDataGrid<Redemption>({
    queryOptions: { refetchInterval: 3000, refetchIntervalInBackground: false },
    filters: {
      permanent: [{ field: "branchId", operator: "eq", value: branch?.id }],
    },
  });
  const theme = useTheme();

  const refreshRedemption = (code: string) => {
    setRefreshing(true);
    axios.get(apiHost + "/redemptions/" + code).then(() => {
      setRefreshing(false);
    });
  };

  return (
    <List>
      <RedemptionFilter
        setFilters={setFilters}
        filters={filters}
        exportDisabled={dataGridProps.rowCount === 0}
      />
      <DataGrid
        columns={redemptionCols({
          theme,
          refreshing,
          refreshRedemption,
          coin: branch?.coin,
        })}
        {...dataGridProps}
        autoHeight
        sx={{
          ...dataGridProps.sx,
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
      />
    </List>
  );
}
