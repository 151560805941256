import { GraphicalDashboardCard } from "../../components/dashboard/GraphicalDashboardCard";
import React from "react";
import { DashboardData } from "../../types/dashboardData";
import { Box, Typography } from "@mui/material";

export function NewsCard(props: { data: DashboardData }) {
  const { data } = props;
  return (
    <GraphicalDashboardCard
      gridProps={{ xs: 12, md: 6 }}
      title={"News"}
      resource={"news"}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        marginX={"auto"}
        marginY={"auto"}
        gap={4}
      >
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <Typography
            variant={"h2"}
            textAlign={"center"}
            color={"grey"}
            fontWeight={700}
          >
            {data.news.publishedNews}
          </Typography>
          <Typography
            variant={"h6"}
            textAlign={"center"}
            color={"grey"}
            fontWeight={300}
          >
            Published
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <Typography
            variant={"h2"}
            textAlign={"center"}
            color={"darkgrey"}
            fontWeight={700}
          >
            {data.news.upcomingNews}
          </Typography>
          <Typography
            variant={"h6"}
            textAlign={"center"}
            color={"darkgrey"}
            fontWeight={300}
          >
            Upcoming
          </Typography>
        </Box>
      </Box>
    </GraphicalDashboardCard>
  );
}
