import { Button, Chip, CircularProgress, MenuItem, Theme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { MembershipTypeCell } from "../components/table/MembershipTypeCell";
import { MoreActionsButton } from "components/table/MoreActionsButton";
import QrCodeDownloadMenuItem from "components/table/QrCodeDownloadMenuItem";
import QRCode from "react-qr-code";
import { ofRedemption, Redemption, RedemptionStatus } from "types/redemption";
import CachedIcon from "@mui/icons-material/Cached";
import { Coin } from "../types/coin";
import DeleteMenuItem from "../components/table/DeleteMenuItem";
import CoinAmount from "../components/CoinAmount";

export const redemptionCols = (props: {
  theme: Theme;
  refreshing: boolean;
  refreshRedemption: (code: string) => void;
  coin?: Coin | null;
}): GridColDef<Redemption>[] => {
  const optionalCoinCol: GridColDef<Redemption>[] = props.coin
    ? [
        {
          field: ofRedemption("includedCoinAmount"),
          headerName: "Included Coin",
          type: "number",
          filterable: false,
          renderCell: ({ row }) => (
            <CoinAmount amount={row.includedCoinAmount} />
          ),
        },
      ]
    : [];
  return [
    {
      field: ofRedemption("membershipType"),
      headerName: "Membership",
      flex: 1,
      renderCell: function render({ row }) {
        return (
          <MembershipTypeCell
            membershipType={row.membershipType}
            mintAddress={row.mintAddress}
          />
        );
      },
    },
    {
      field: ofRedemption("serial"),
      headerName: "Serial",
      type: "number",
      filterable: false,
    },
    ...optionalCoinCol,
    {
      field: ofRedemption("createdAt"),
      headerName: "Created at",
      flex: 1,
      renderCell: function render({ row }) {
        return <span>{new Date(row.createdAt).toLocaleString()}</span>;
      },
    },
    {
      field: "redeemedAt",
      headerName: "Redeemed at",
      filterable: false,
      flex: 1,
      renderCell: function render({ row }) {
        return row.redeemedAt ? (
          <>{new Date(row.redeemedAt).toLocaleString()}</>
        ) : (
          "-"
        );
      },
    },
    {
      field: ofRedemption("status"),
      headerName: "Status",
      renderCell: function render({ row }) {
        return (
          <Chip
            color={
              row.status === RedemptionStatus.REDEEMED
                ? "success"
                : row.status === RedemptionStatus.VALID
                ? "info"
                : "default"
            }
            label={row.status}
            variant="outlined"
          />
        );
      },
      hideable: false,
      flex: 1,
    },
    {
      field: " ",
      renderCell: function render({ row }) {
        return (
          <MoreActionsButton>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                margin: 8,
                marginBottom: 16,
              }}
            >
              <QRCode value={row.qrCodeUrl} size={128} />
            </div>
            <QrCodeDownloadMenuItem
              url={row.qrCodeUrl}
              filename={`redemption-${row.id}-code.png`}
            />
            {row.status === RedemptionStatus.VALID && (
              <MenuItem onClick={() => props.refreshRedemption(row.code)}>
                <Button
                  startIcon={
                    props.refreshing ? (
                      <CircularProgress size={15} sx={{ marginRight: 1 }} />
                    ) : (
                      <CachedIcon />
                    )
                  }
                >
                  Refresh
                </Button>
              </MenuItem>
            )}
            <DeleteMenuItem resource="redemptions" id={row.code} />
          </MoreActionsButton>
        );
      },
      align: "center",
      maxWidth: 80,
      filterable: false,
      sortable: false,
      disableExport: true,
      disableColumnMenu: true,
    },
  ];
};
