import { List } from "@refinedev/mui";
import { News } from "types/news";
import { DataGrid } from "@mui/x-data-grid";
import { newsCols } from "../../tables/newsCols";
import { useBranchDependentDataGrid } from "../../utils/hooks/useBranchDependentDataGrid";

export default function NewsList() {
  const { dataGridProps } = useBranchDependentDataGrid<News>({});

  return (
    <List>
      <DataGrid {...dataGridProps} columns={newsCols} autoHeight />
    </List>
  );
}
