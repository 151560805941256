import { Box, FormControl, Grid, InputLabel, Typography } from "@mui/material";
import { Create } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import FormTextField from "components/form/FormTextField";
import SelectMembership from "../../components/form/SelectMembership";
import React, { useState } from "react";
import { FormProvider, useFormContext } from "react-hook-form";
import {
  CreateRedemptionsRequest,
  defaultRedemptionsRequest,
  ofCreateRedemption,
} from "types/redemption";
import { useMembershipTypes } from "../../utils/hooks/useMembershipTypes";
import { useBranch } from "../../provider/BranchProvider";
import { IOSSwitch } from "../../components/layout/IOSSwitch";

export default function CreateRedemptions() {
  const membershipTypes = useMembershipTypes();
  const methods = useForm<CreateRedemptionsRequest>({
    defaultValues: defaultRedemptionsRequest,
  });

  const membershipTypeId = methods.watch("membershipTypeId");
  const includedCoinAmount = membershipTypes?.find(
    (m) => m.id === membershipTypeId
  )?.includedCoinAmount;

  if (membershipTypes && membershipTypes.length === 0) {
    return (
      <p>
        No Membership types found.
        <br />
        At least one membership type is required before creating any
        redemptions.
      </p>
    );
  }

  return (
    <Create
      saveButtonProps={methods.saveButtonProps}
      title={<Typography variant="h5">Create Redemptions</Typography>}
    >
      <FormProvider {...methods}>
        <CreateRedemptionsForm
          includedCoin={includedCoinAmount ? includedCoinAmount : 0}
        />
      </FormProvider>
    </Create>
  );
}

function CreateRedemptionsForm({ includedCoin }: { includedCoin: number }) {
  const { control } = useFormContext();
  const branch = useBranch();
  const [switchChecked, setSwitchChecked] = useState<boolean>(false);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchChecked(event.target.checked);
  };

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <FormControl variant="standard" required>
        <InputLabel>Select Membership Type</InputLabel>
        <SelectMembership name="membershipTypeId" control={control} />
      </FormControl>

      <FormTextField
        required
        control={control}
        name={ofCreateRedemption("amountOfRedemptions")}
        label="Amount of redemptions to create"
        type="number"
        fullWidth
        additionalRules={{
          min: 0,
        }}
      />
      {branch?.coin && (
        <>
          <div>Coin Amount included in Membership: {includedCoin / 100}</div>

          <Grid container alignItems="center" style={{ display: "flex" }}>
            <div>Customize coin amount</div>
            <IOSSwitch
              onChange={handleSwitchChange}
              checked={switchChecked}
            ></IOSSwitch>
          </Grid>

          {switchChecked && (
            <FormTextField
              control={control}
              name={ofCreateRedemption("includedCoinAmount")}
              label="Included Coin"
              helperText={
                "The amount of coin a member will receive when redeeming this membership."
              }
              type="number"
              fullWidth
              additionalRules={{
                min: 0,
              }}
              transform={{
                input: (v: any) => {
                  if (!v) return v;
                  // TODO: use coin decimals
                  return v * 100;
                },
                output: (v: any) => {
                  if (!v) return v;
                  // TODO: use coin decimals
                  return v / 100;
                },
              }}
            />
          )}
        </>
      )}
    </Box>
  );
}
