import { Box, Grid } from "@mui/material";
import { Create } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import FormDateField from "components/form/FormDateField";
import FormImageField from "components/form/FormImageField";
import FormTextField from "components/form/FormTextField";
import { FieldValues, FormProvider } from "react-hook-form";
import { CouponType, defaultCouponType } from "../../types/couponType";
import { useBranch } from "../../provider/BranchProvider";
import { COUPON_ASPECT_RATIO } from "../../types/coupon";

export default function CreateCouponType() {
  const methods = useForm<CouponType>({
    defaultValues: defaultCouponType,
  });
  const branch = useBranch();
  const customHandleSubmit = (values: FieldValues) => {
    return methods.refineCore.onFinish({ ...values, branchId: branch?.id });
  };
  const { control } = methods;

  return (
    <Create
      saveButtonProps={{
        ...methods.saveButtonProps,
        onClick: methods.handleSubmit(customHandleSubmit),
      }}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <FormTextField
            name={"name"}
            control={control}
            label={"Name"}
            required
            additionalRules={{
              maxLength: {
                value: 32,
                message: "Name must be at most 32 characters long",
              },
            }}
          />
          <FormTextField
            name={"description"}
            control={control}
            label={"Description"}
            multiline
            additionalRules={{
              maxLength: {
                value: 250,
                message: "Description must be at most 250 characters long",
              },
            }}
          />
          <FormTextField
            name={"externalUrl"}
            control={control}
            label={"External Link"}
          />
          <Box>
            <FormDateField
              name="validUntil"
              control={control}
              label={"Valid until"}
              dateProps={{
                disablePast: true,
              }}
              additionalRules={{
                validate: (date, _) =>
                  (date ? new Date(date) >= new Date() : true) ||
                  "Date must be in the future",
              }}
            />
          </Box>
          <Grid container spacing={4} marginTop={1} direction={"column"}>
            <Grid item>
              <FormImageField
                control={control}
                name={"image"}
                required
                aspect={COUPON_ASPECT_RATIO}
              />
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </Create>
  );
}
