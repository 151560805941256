import { GridColDef } from "@mui/x-data-grid";
import {
  ofTransactionTransfers,
  TransactionTransfer,
} from "../types/transaction";
import { Box } from "@mui/material";

export const transferCols: GridColDef<TransactionTransfer>[] = [
  {
    field: ofTransactionTransfers("name"),
    headerName: "Name",
    flex: 1,
  },
  {
    field: ofTransactionTransfers("amount"),
    headerName: "Amount",
    flex: 1,
  },
  {
    field: ofTransactionTransfers("image"),
    headerName: "",
    renderCell: function render({ row }) {
      return (
        row.image?.url && (
          <Box
            component="img"
            src={row.image?.url}
            sx={(theme) => ({
              verticalAlign: "middle",
              maxWidth: theme.spacing(6),
            })}
          />
        )
      );
    },
    minWidth: 120,
    sortable: false,
    flex: 1,
  },
];
