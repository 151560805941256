import { useBranchDependentDataGrid } from "../../utils/hooks/useBranchDependentDataGrid";
import { List } from "@refinedev/mui";
import { DataGrid } from "@mui/x-data-grid";
import { transactionCols } from "../../tables/transactionCols";
import { Transaction } from "../../types/transaction";
import { useNavigation } from "@refinedev/core";

export default function TransactionList() {
  const { dataGridProps } = useBranchDependentDataGrid<Transaction>({});

  const { show } = useNavigation();

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        columns={transactionCols()}
        autoHeight
        onRowClick={(params) => {
          show("transactions", params.row.id);
        }}
      />
    </List>
  );
}
