import { EntityRef } from "utils/rest/entities";
import { propertiesOf } from "../utils/propertyOf";
import { ImageDto } from "./image";

/**
 * https://en.wikipedia.org/wiki/ISO/IEC_7810
 */
export const CREDIT_CARD_ASPECT_LANDSCAPE = 3.37 / 2.125;
export const CREDIT_CARD_ASPECT_PORTRAIT = 2.125 / 3.37;

/** Mirrors key2-server's [com.kurzdigital.key2.key2server.dto.MembershipDto] */
export interface Membership extends EntityRef {
  id: number;
  address: string;
  name: string;
  symbol: string;
  serial: number;
  image: ImageDto | null;
  membershipTypeId: number;
  walletAddress: string;
  memberId: number;
  description?: string;
  externalUrl?: string;
}

export const ofMembership = propertiesOf<Membership>();
